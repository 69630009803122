<template>
  <el-main>
    <el-row type="flex" class="row-bg" justify="center" v-if="false">
      <el-col class="bigTitle" :span="6" v-if="type === 'add'"
        >新增会议纪要</el-col
      >
      <el-col class="bigTitle" :span="6" v-if="type === 'edit'"
        >修改会议纪要</el-col
      >
    </el-row>
    <el-form
      :model="meetingInfo"
      ref="meetingInfo"
      :rules="rules"
      class="addDailogEdit"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
    >
      <el-divider content-position="left">关联事项</el-divider>
      <el-row type="flex" justify="start">
        <el-form-item label="相关日程" prop="relatedScheduleId">
          <el-select
            v-model="meetingInfo.relatedScheduleId"
            filterable
            @visible-change="loadrelatedSchedules($event)"
            @change="relatedScheduleChange($event)"
            placeholder="请选择日程"
            clearable
            no-data-text="加载中···"
            :disabled="editDisabled('group2')"
          >
            <el-option
              v-for="item in relatedScheduleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <!-- <el-input
            v-model="meetingInfo.relatedScheduleTitle"
            v-if="editDisabled('group1')"
            :disabled="editDisabled('group1')"
          >
          </el-input> -->
        </el-form-item>
        <el-form-item label-width="0" style="padding-left: 20px">
          <el-radio-group>
            <el-checkbox
              v-model="isProjectMeeting"
              :disabled="editDisabled('group2')"
              @change="meetingTypeChanged('project')"
              >项目会议
            </el-checkbox>
            <el-checkbox
              v-model="isInvestMeeting"
              :disabled="editDisabled('group2')"
              @change="meetingTypeChanged('invest')"
              >投资人会议
            </el-checkbox>
            <el-checkbox
              v-model="isOtherMeeting"
              :disabled="editDisabled('group2')"
              @change="meetingTypeChanged('other')"
              >其它会议
            </el-checkbox>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row type="flex" justify="start" v-if="isProjectMeeting">
        <el-form-item label="项目会议" prop="projectId">
          <el-select
            v-model="meetingInfo.projectId"
            class="childWidth"
            filterable
            placeholder="请选择项目"
            @visible-change="searchPorjectList($event)"
            @change="choosePorject($event)"
            clearable
            :disabled="editDisabled('group2')"
            :no-data-text="!porjectListOptions ? '加载中···' : '暂无数据'"
          >
            <el-option
              v-for="item in porjectListOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="0" prop="projectWorkflowDetailId">
          <el-select
            v-model="meetingInfo.projectWorkflowDetailId"
            filterable
            :reserve-keyword="false"
            placeholder="请选择业务流程节点"
            @visible-change="searchWorkflowList($event)"
            @change="chooseWorkflow($event)"
            clearable
            class="childWidth"
            :disabled="editDisabled('group3')"
            :no-data-text="!workflowListOptions ? '加载中···' : '暂无数据'"
          >
            <el-option
              v-for="item in workflowListOptions"
              :key="item.id"
              :label="item.nodeName"
              :value="item.id"
            >
              <span style="float: left">{{ item.nodeName }}</span>
              <span
                style="
                  float: left;
                  color: #8492a6;
                  font-size: 13px;
                  margin-left: 6px;
                "
                >{{ item.projectWorkflowName }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="0" prop="businessId">
          <div class="flexJsbAc">
            <el-select
              v-model="meetingInfo.businessId"
              @visible-change="getTaskSelectList($event)"
              filterable
              placeholder="请选择任务"
              clearable
              :disabled="editDisabled('group3')"
              :no-data-text="!taskSelectList ? '加载中···' : '暂无数据'"
            >
              <el-option
                v-for="item in taskSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-row>

      <el-row type="flex" justify="start" v-if="isInvestMeeting">
        <el-form-item label="投资人会议" prop="institutionId">
          <el-select
            v-model="meetingInfo.institutionId"
            class="childWidth"
            filterable
            @visible-change="searchPorjectList($event)"
            placeholder="请选择投资机构"
            clearable
            :disabled="editDisabled('group2')"
            :no-data-text="!porjectListOptions ? '加载中···' : '暂无数据'"
            @change="institutionIdChange($event)"
          >
            <el-option
              v-for="item in porjectListOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="0" prop="projectWorkflowDetailId">
          <el-select
            v-model="meetingInfo.projectWorkflowDetailId"
            filterable
            :reserve-keyword="false"
            placeholder="请选择业务流程节点"
            @visible-change="searchWorkflowList2($event)"
            @change="chooseWorkflow2($event)"
            clearable
            class="childWidth"
            :disabled="editDisabled('group3')"
            :no-data-text="!workflowListOptions2 ? '加载中···' : '暂无数据'"
          >
            <el-option
              v-for="item in workflowListOptions2"
              :key="item.id"
              :label="item.nodeName"
              :value="item.id"
            >
              <span style="float: left">{{ item.nodeName }}</span>
              <span
                style="
                  float: left;
                  color: #8492a6;
                  font-size: 13px;
                  margin-left: 6px;
                "
                >{{ item.projectWorkflowName }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" label-width="0" prop="">
          <el-select
            v-model="meetingInfo.investorTaskId"
            @visible-change="getInstitutionTaskSelectList($event)"
            @change="institutionTaskChange($event)"
            placeholder="请选择任务"
            clearable
            :disabled="editDisabled('group3')"
            :no-data-text="
              !institutionTaskSelectList ? '加载中···' : '暂无数据'
            "
          >
            <el-option
              v-for="item in institutionTaskSelectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row type="flex" justify="start" v-if="isInvestMeeting">
        <el-form-item label="投资人" prop="businessId">
          <el-select
            v-model="meetingInfo.businessId"
            class="childWidth"
            filterable
            placeholder="请选择投资人"
            @visible-change="selectListInvestor($event)"
            @change="investorChange($event)"
            clearable
            :no-data-text="!investorwListOptions ? '加载中···' : '暂无数据'"
          >
            <el-option
              v-for="item in investorwListOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>

      <el-row type="flex" justify="start" v-if="isOtherMeeting">
        <el-form-item label="其它会议" prop="businessId">
          <div class="flexJsbAc">
            <el-select
              v-model="meetingInfo.businessId"
              @visible-change="getOtherTaskSelectList($event)"
              filterable
              placeholder="请选择任务"
              clearable
              :disabled="editDisabled('group2')"
              :no-data-text="!otherTaskSelectList ? '加载中···' : '暂无数据'"
            >
              <el-option
                v-for="item in otherTaskSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-row>

      <el-divider content-position="left">会议基本信息</el-divider>

      <el-row type="flex" justify="start">
        <el-form-item label="会议主题" prop="subject">
          <el-input
            @change="subjecChange()"
            v-model="meetingInfo.subject"
            class="childWidth"
          ></el-input>
        </el-form-item>

        <el-form-item label="会议日期" prop="beginTime">
          <el-date-picker
            v-model="meetingInfo.beginTime"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
            style="width: 100% !important"
            value-format="timestamp"
            default-time="09:00:00"
            @change="beginTimeChange()"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="会议地点" prop="place">
          <el-input v-model="meetingInfo.place" class="childWidth"></el-input>
        </el-form-item>
      </el-row>

      <el-row type="flex" justify="start">
        <el-form-item label="与会人员(内部)" prop="internalUserList">
          <el-select
            v-model="meetingInfo.internalUserList"
            multiple
            @visible-change="projectTeam($event)"
            filterable
            placeholder="请选择"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in internalUseroptionsList"
              :key="item.uid"
              :label="item.realName"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="与会人员(外部)" prop="externalUsers">
          <el-input
            v-model="meetingInfo.externalUsers"
            class="childWidth"
          ></el-input>
        </el-form-item>
      </el-row>

      <el-form-item
        label="会议内容"
        prop="content"
        style="width: 100% !important; padding: 10px 0 10px 0"
      >
        <quill-editor
          ref="myTextEditor"
          v-model="meetingInfo.content"
          :options="editorOption"
          style="height: 400px; verflow: auto"
        ></quill-editor>
      </el-form-item>
      <!--  -->
      <el-form-item
        label="跟进事项"
        prop="matterList"
        style="width: 100% !important; margin: 70px 0 0px 0"
      >
        <div style="width: 100%" class="">
          <!-- 表格 -->
          <el-button
            type="primary"
            size="mini"
            v-show="this.meetingInfo.matterList.length == 0"
            @click="addMatterOne2"
            >新增跟进事项
          </el-button>
          <el-table
            v-loading="loadingTable"
            border
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="#DCDFE6"
            :data="meetingInfo.matterList"
            style="width: 100%; margin: 0px 0 0px 0"
          >
            <el-table-column
              label="事项"
              fixed="left"
              prop="content"
              width="155"
            >
              <template slot-scope="scope">
                <el-input v-model="scope.row.content"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="负责人" prop="ownerId" width="155">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.ownerId"
                  @visible-change="projectTeam($event)"
                  filterable
                  placeholder="请选择"
                  clearable
                  no-data-text="加载中···"
                >
                  <el-option
                    v-for="item in internalUseroptionsList"
                    :key="item.uid"
                    :label="item.realName"
                    :value="item.uid"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="目标完成日期" prop="endTime" width="235">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.endTime"
                  type="datetime"
                  value-format="timestamp"
                  placeholder="选择日期时间"
                  align="center"
                  :picker-options="pickerOptions"
                  default-time="18:00:00"
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" width="155">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.status"
                  placeholder="请选择"
                  clearable
                  no-data-text="加载中···"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.key"
                    :label="item.key"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" width="155">
              <template slot-scope="scope">
                <el-input v-model="scope.row.remark"></el-input>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <i
                  class="el-icon-circle-plus-outline el-icon-iconName"
                  @click="addMatterOne(scope.row.index)"
                ></i>
                <i
                  class="el-icon-remove-outline el-icon-iconName"
                  @click="removeMatterOne(scope.$index, meetingInfo.matterList)"
                ></i>
              </template>
            </el-table-column>
          </el-table>

          <!-- <div class="page-info-class">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="this.pageNum"
              :page-sizes="[5, 10]"
              :page-size="this.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total"
            ></el-pagination>
          </div> -->
        </div>
      </el-form-item>
      <el-form-item
        label="上传附件"
        prop="content"
        style="width: 100% !important; margin-top: 20px"
      >
        <!-- 上传文件 -->
        <addFile
          :info="addFileInfo"
          type="file"
          :detailsFiles="detailsFiles"
          v-on:listenAddFileClose="listenAddFileClose"
        ></addFile>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="reset">重置</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-main>
</template>

<script>
import Vue from "vue";
import {
  queryMeetingDetail,
  addMeeting,
  editMeeting,
  selectListMeetingProject,
  selectListWorkflow,
  selectListInvestor,
} from "@/api/minutesOfMeeting";
import { projectTeam } from "@/api/projectManage";
import { formatDate } from "@/common/date";
import { getTaskQueryList1, getTaskSelectList } from "@/api/myMission";
import {
  getCalendarScheduleSelectList,
  getCalendarThingDetails,
} from "@/api/calendar";
import { getInstitutionSelectList } from "@/api/institutionManage";
import { quillEditor } from "vue-quill-editor";
import Quill from "quill"; //引入编辑器
// 富文本编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

//封装 quill-config 方法
import quillConfig from "@/common/quill-config.js";
import addFile from "@/components/minutesOfMeeting/addFile";
export default {
  name: "edit",

  components: {
    quillEditor,
    addFile,
  },
  computed: {},

  props: {
    editDialog: Boolean,
    threadId: Number,
    options: Object, // type(=add/edit), meetingType, projectId, taskId, projectWorkflowDetailId,
    jumpBack: String,
  },

  data() {
    let validateSchedule = (rule, value, callback) => {
      if (
        !value &&
        !this.isProjectMeeting &&
        !this.isInvestMeeting &&
        !this.isOtherMeeting
      ) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };

    let validateProject = (rule, value, callback) => {
      if (!value && this.isProjectMeeting) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };

    let validateInvest = (rule, value, callback) => {
      console.info(rule);
      console.info(value);
      if (!value && this.isInvestMeeting) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };

    let validateOtherTask = (rule, value, callback) => {
      if (!value && this.isOtherMeeting) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };

    return {
      type: "add",
      token: null,
      meetingInfo: {
        subject: null,
        beginTime: null,
        investorTaskId: null,
      },
      defaultMeetingInfo: {
        projectName: null,
        matterList: [],
        businessType: 0, //0:日程 1:项目流程节点(已不用) 2:投资人 3:关联任务  此字段将会被作废，改用 meetingType
        projectId: null,
        businessId: null,
        internalUserList: [],
        projectWorkflowDetailId: null,
        relatedScheduleId: null, // 选中的关联事项id
        institutionId: null, // 投资机构id
        meetingType: "schedule",
      },
      searchPorjectListLoading: false,
      searchWorkflowListLoading: false,
      porjectListOptions: null,
      workflowListOptions: null,
      investorwListOptions: null,
      internalUseroptionsList: null,
      institutionTaskSelectList: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pageNum: 1,
      pageSize: 10,
      total: null,
      pageCount: null,
      loadingTable: false,
      statusList: [
        { key: "未开始", value: 0 },
        { key: "进行中", value: 1 },
        { key: "已完成", value: 2 },
      ],
      rules: {
        relatedScheduleId: [
          {
            validator: validateSchedule,
            message: "请至少选择一项关联事项",
            trigger: "change",
          },
        ],
        beginTime: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
        subject: [{ required: true, message: "请输入主题", trigger: "blur" }],
        internalUserList: [
          {
            required: true,
            message: "请选择与会人员（内部）",
            trigger: "blur",
          },
        ],
        projectId: [
          {
            validator: validateProject,
            message: "请选择项目",
            trigger: "change",
          },
        ],
        projectWorkflowDetailId: [
          {
            validator: validateProject,
            message: "请选择业务流程节点",
            trigger: "change",
          },
          {
            validator: validateInvest,
            message: "请选择业务流程节点",
            trigger: "change",
          },
        ],
        businessId: [
          {
            validator: validateProject,
            message: "请选择任务",
            trigger: "change",
          },
          {
            validator: validateInvest,
            message: "请选择投资人",
            trigger: "change",
          },
          {
            validator: validateOtherTask,
            message: "请选择任务",
            trigger: "change",
          },
        ],
        institutionId: [
          {
            validator: validateInvest,
            message: "请选择投资机构",
            trigger: "change",
          },
        ],
        investorTaskId: [
          // { type:"string", required: true, message: "请选择任务", trigger: ["blur","change"] }
          {
            type: "string",
            validator: validateInvest,
            message: "请选择任务",
            trigger: ["blur", "change"],
          },
        ],
      },
      editor: null,
      editorData: "",
      taskSelectList: [],

      // editorOption: {
      //   placeholder: "请输入会议内容",
      //   modules: {
      //     // handlers: handlers,  // 事件重写
      //     toolbar: toolbarButtons,

      //   },
      // },
      editorOption: quillConfig,

      relatedScheduleList: [], //关联事项列表

      isProjectMeeting: false,
      isInvestMeeting: false,
      isOtherMeeting: false,

      institutionList: [],
      otherTaskSelectList: [],

      oldMeetingInfo: {}, // 修改前的会议纪要

      addFileInfo: {
        relevantFile: "会议纪要",
      },
      detailsFiles: null,
      workflowListOptions2: [],
      institutionVirtualId: null,
    };
  },

  watch: {
    threadId: {
      handler(newVal, oldVal) {
        console.log(newVal);
        if (newVal && newVal != oldVal) {
          this.initData(this.options);
        }
      },
      immediate: true,
    },
  },

  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },

  created() {},

  mounted() {},

  methods: {
    subjecChange() {
      this.$forceUpdate();
    },
    beginTimeChange() {
      this.$forceUpdate();
    },
    //  addFile(val) {
    //   //监听要上传的文件
    //   this.addFileArr = val;
    // },
    //监听文件删除
    deleteFile(value) {
      this.meetingInfo.attachmentList = value;
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      const { type, isClose, files, mold } = params;
      if (type == "sure") {
        if (mold == "picture") {
          let fileUrls = [];
          let file = JSON.parse(JSON.stringify(params.files));
          file.forEach((item, index) => {
            fileUrls.push(item.url);
          });
          console.log(fileUrls);
        } else {
          let fileUrls = [];
          let file = JSON.parse(JSON.stringify(params.files));
          file.forEach((item, index) => {
            fileUrls.push(item.url);
          });
          console.log(fileUrls);
          console.log(files);
          this.meetingInfo.attachmentList = files;
        }
      }
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    initData(options) {
      console.info(options);
      // let options = JSON.parse(JSON.stringify(options));
      this.loadrelatedSchedules(true);
      this.projectTeam(true);
      getInstitutionSelectList().then((list) => {
        this.institutionList = list;
      });

      this.type = "add";

      this.meetingInfo = Object.assign({}, this.defaultMeetingInfo);
      this.meetingInfo.matterList = [];

      let one = {};
      one.content = null;
      one.status = 0;
      one.remark = null;
      one.endTime = null;
      this.meetingInfo.matterList.push(one);

      if (this.$refs.meetingInfo) {
        this.$refs.meetingInfo.resetFields();
      }

      if (options) {
        if (options.type) {
          this.type = options.type;
        }

        if (options.type === "add") {
          if (options.meetingType) {
            this.meetingInfo.meetingType = options.meetingType;
            switch (options.meetingType) {
              case "project":

              case "other":
                this.meetingInfo.businessType = 3;
                break;
              case "invest":
                this.meetingInfo.businessType = 2;
                break;
            }
          }
          if (options.projectId) {
            this.meetingInfo.projectId = options.projectId;
          }
          if (options.scheduleId) {
            this.meetingInfo.relatedScheduleId = options.scheduleId;
          }
          if (options.extId) {
            this.meetingInfo.extId = options.extId;
          }
          if (options.extType != "") {
            this.meetingInfo.extType = options.extType;
            if (options.extType == "project") {
              this.isProjectMeeting = true;
              this.meetingTypeChanged("project");
              this.meetingInfo.projectId = options.extId;
            } else if (options.extType == "organization") {
              this.isInvestMeeting = true;
              this.meetingTypeChanged("invest");
              this.meetingInfo.institutionId = options.extId;
            }
            this.$forceUpdate();
          }
          if (options.projectWorkflowDetailId) {
            this.meetingInfo.projectWorkflowDetailId =
              options.projectWorkflowDetailId;
          }

          if (options.taskId) {
            this.meetingInfo.businessId = options.taskId;
          }

          if (options.investorTaskId) {
            this.meetingInfo.investorTaskId = options.investorTaskId;
          }

          this.loadUIData();
        } else if (options.type === "edit") {
          const meetingId = options.meetingId;
          this.queryMeetingDetail({ id: meetingId });
        }
      } else {
        this.loadUIData();
      }

      scrollTo(0, 0);
    },

    loadUIData() {
      const meetingType = this.meetingInfo.meetingType;
      this.isProjectMeeting = meetingType === "project";
      this.isInvestMeeting = meetingType === "invest";
      this.isOtherMeeting = meetingType === "other";

      if (this.meetingInfo.projectId) {
        this.searchPorjectList(true);
      }

      if (this.meetingInfo.projectWorkflowDetailId) {
        if (meetingType === "project") {
          this.searchWorkflowList(true);
        } else if (meetingType === "invest") {
          this.searchWorkflowList2(true);
        }
      }

      if (this.meetingInfo.businessId) {
        if (meetingType === "other") {
          this.getOtherTaskSelectList(true);
        } else if (meetingType === "project") {
          this.getTaskSelectList(true);
        } else if (meetingType === "invest") {
          console.log("投资人会议");
          this.selectListInvestor(true);
        }
      }

      if (this.meetingInfo.relatedScheduleId) {
        this.relatedScheduleChange(this.meetingInfo.relatedScheduleId);
        this.$forceUpdate();
      }
      if (this.meetingInfo.investorTaskId) {
        if (meetingType === "invest") {
          this.getInstitutionTaskSelectList(true);
        }
      }
      //跟进事项
      this.total = this.meetingInfo.matterList.length;
      this.loadingTable = false;

      this.$nextTick(() => {
        if (this.$refs.meetingInfo) {
          this.$refs.meetingInfo.clearValidate();
        }
      });
    },

    getTaskSelectList(cb) {
      if (cb) {
        this.taskSelectList = [];
        if (!this.meetingInfo.projectWorkflowDetailId) {
          this.$message({ type: "warning", message: "请先选择业务流程节点!" });
          return false;
        }
        let info = {
          relationType: 1,
          relationBizId: this.meetingInfo.projectWorkflowDetailId,
        };

        getTaskSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;
            this.taskSelectList = list;
          }
        });
      }
    },

    addMatterOne2() {
      let one = {};
      one.content = null;
      one.status = 0;
      one.remark = null;
      one.endTime = null;
      one.ownerId = null;
      one.temporaryIndex = Number(1);
      this.meetingInfo.matterList.push(one);
    },

    addMatterOne(index) {
      let one = {};
      let matterListLength = this.meetingInfo.matterList.length;
      one.content = null;
      one.status = 0;
      one.remark = null;
      one.endTime = null;
      one.ownerId = null;
      one.temporaryIndex = this.meetingInfo.matterList[matterListLength - 1]
        .temporaryIndex++;
      this.meetingInfo.matterList.push(one);
    },

    removeMatterOne(index, row) {
      console.log(index + "----1uiuiuiu");
      console.log(row + "----1uiuiuiu");
      if (this.meetingInfo.matterList.length) {
        row.splice(index, 1);
      }
    },

    handleSizeChange(val) {
      //   this.queryMeetingList(1, val);
    },

    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      //   this.queryMeetingList(val, this.pageInfo.pageSize);
    },

    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },

    chooseWorkflow() {
      this.meetingInfo.businessId = null;
    },
    chooseWorkflow2() {
      this.meetingInfo.investorTaskId = null;
    },
    choosePorject(id) {
      var that = this;
      console.log(id);
      that.meetingInfo.projectWorkflowDetailId = null;
      that.meetingInfo.businessId = null;
      that.porjectListOptions.forEach(function (item, index) {
        if (id == item.id) {
          that.meetingInfo.projectId = item.id;
          that.meetingInfo.projectName = item.name;
        }
      });
    },

    async searchPorjectList(cb) {
      if (cb) {
        this.porjectListOptions = [];
        let info = {
          scheduleId: this.meetingInfo.relatedScheduleId,
        };
        if (this.isProjectMeeting == true) {
          info.isVirtual = 0;
        }
        if (this.isInvestMeeting == true) {
          info.isVirtual = 1;
          info.virtualType = "organization";
        }
        const response = await selectListMeetingProject(info);
        if (response.success) {
          this.porjectListOptions = response.data;
        }
      }
    },
    institutionIdChange(id) {
      let that = this;
      console.log(id);
      that.meetingInfo.projectWorkflowDetailId = null;
      that.meetingInfo.investorTaskId = null;
      that.meetingInfo.businessId = null;
      that.porjectListOptions.forEach(function (item, index) {
        if (id == item.id) {
          that.institutionVirtualId = item.virtualId;
        }
      });
      this.$forceUpdate();
    },
    selectListInvestor(cb) {
      let that = this;
      let name = null;
      let organizationId = this.institutionVirtualId;
      if (cb) {
        if (!this.institutionVirtualId) {
          that.porjectListOptions.forEach(function (item, index) {
            if (that.meetingInfo.institutionId == item.id) {
              that.institutionVirtualId = item.virtualId;
            }
          });
        }
        selectListInvestor(
          name,
          organizationId || that.institutionVirtualId
        ).then((response) => {
          if (response.success) {
            that.investorwListOptions = response.data;
          }
        });
      }
    },
    investorChange(data) {
      this.$forceUpdate();
    },

    searchWorkflowList(cb) {
      this.workflowListOptions = [];
      if (cb) {
        if (!this.meetingInfo.projectId) {
          this.$message({ type: "warning", message: "请先选择项目!" });
          return false;
        }
        let param = {};
        param.nodeName = null;
        param.projectId = this.meetingInfo.projectId;
        param.projectName = this.meetingInfo.projectName;
        selectListWorkflow(param).then((response) => {
          if (response.success) {
            this.workflowListOptions = response.data;
            if (
              this.workflowListOptions.length == 0 ||
              !this.workflowListOptions
            ) {
              this.$message({ type: "warning", message: "请先创建业务流程!" });
            }
          }
        });
      }
    },
    searchWorkflowList2(cb) {
      this.workflowListOptions2 = [];
      if (cb) {
        if (!this.meetingInfo.institutionId && this.type == "add") {
          this.$message({ type: "warning", message: "请先选择投资机构!" });
          return false;
        }
        let param = {};
        param.nodeName = null;
        param.projectId = this.meetingInfo.institutionId;
        // param.projectName = this.meetingInfo.projectName;
        selectListWorkflow(param).then((response) => {
          if (response.success) {
            this.workflowListOptions2 = response.data;
            if (
              this.workflowListOptions2.length == 0 ||
              !this.workflowListOptions2
            ) {
              this.$message({ type: "warning", message: "请先创建业务流程!" });
            }
          }
        });
      }
    },

    queryMeetingDetail(id) {
      this.loadingTable = true;
      queryMeetingDetail(id).then((res) => {
        if (res.success) {
          this.meetingInfo = res.data;
          this.oldMeetingInfo = Object.assign({}, this.meetingInfo);

          //与会人员
          let internalUserList = this.meetingInfo.internalUserList;
          internalUserList.map(function (item, index) {
            internalUserList[index] = internalUserList[index].uid;
          });
          this.detailsFiles = this.meetingInfo.attachmentList;
          if (res.data.relatedScheduleId) {
            this.meetingInfo.relatedScheduleId = String(
              res.data.relatedScheduleId
            );
          }

          this.loadUIData();
        }
      });
    },

    //弹窗关闭
    reset() {
      this.$refs["meetingInfo"].resetFields();
      this.initData();
    },
    add() {
      if (this.meetingInfo.meetingType === "schedule") {
        this.meetingInfo.projectId = null;
        this.meetingInfo.businessId = null;
        this.meetingInfo.institutionId = null;
      }

      let that = this;
      addMeeting(that.meetingInfo)
        .then((response) => {
          if (response.success) {
            that.$message({ type: "success", message: "添加成功!" });
            // that.initData();
            if (that.jumpBack == "minutesOfMeeting") {
              this.$emit("close", {
                jumpBack: "minutesOfMeeting",
              });
              this.$emit("update:jumpBack", null);
            } else {
              that.$emit("close");
            }
          }
        })
        .catch(() => {});
    },

    edit() {
      editMeeting(this.meetingInfo).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          // this.initData();
          this.$emit("close");
        }
      });
    },

    //确定
    sure() {
      this.$refs["meetingInfo"].validate((valid) => {
        if (valid) {
          var that = this;
          if (that.isInvestMeeting && !that.meetingInfo.investorTaskId) {
            that.$message({ type: "warning", message: "请选择任务!" });
            return false;
          }
          if (that.meetingInfo.internalUserList.length > 0) {
            that.meetingInfo.internalUserList.forEach(function (item, index) {
              that.internalUseroptionsList.forEach(function (item2, index2) {
                if (item == item2.uid) {
                  console.log(item2);
                  that.meetingInfo.internalUserList[index] = item2;
                }
              });
            });
          }

          if (that.meetingInfo.matterList.length === 1) {
            // let one = this.meetingInfo.matterList[0];
            let one = Object.values(that.meetingInfo.matterList[0]).filter(
              function (item) {
                if (item !== "" || item !== null) {
                  return true; //过滤掉为空的项
                }
              }
            );
            if (one.length === 0) {
              // 如果过滤后的arr长度为0，表示对象的键值对的值都为空！
              console.log("searchForm这个对象的键值对的值都为空");
              that.$message({ type: "warning", message: "请填写跟进事项!" });
              // that.meetingInfo.matterList = [];
              return false; //过滤掉为空的项
            }
          }

          if (this.type === "add") {
            this.add();
          } else if (this.type === "edit") {
            this.edit();
          }
        }
      });
    },

    // 加载 relatedScheduleList
    async loadrelatedSchedules(cb) {
      if (cb) {
        let scheduleList = [];
        let res = await getCalendarScheduleSelectList({
          unrelatedByMeeting: true,
        });
        if (res.success) {
          scheduleList = res.data;
        }

        this.relatedScheduleList = [];
        for (let s of scheduleList) {
          this.relatedScheduleList.push({
            id: s.id + "",
            name: "日程:" + s.title,
            type: "schedule",
          });
        }
      }
    },

    // 关联事项选择项改变
    async relatedScheduleChange(id) {
      if (!id) {
        return false;
      }
      if (this.meetingInfo.relatedScheduleId != null) {
        const res = await getCalendarThingDetails(
          Number(this.meetingInfo.relatedScheduleId)
        );
        if (res.success) {
          const schedule = res.data;
          await this.searchPorjectList(true);
          if (this.isProjectMeeting == true && !this.options) {
            this.meetingInfo.projectId = null;
          }
          if (this.isInvestMeeting == true && !this.options) {
            this.meetingInfo.institutionId = null;
          }
          if (this.porjectListOptions.length == 1) {
            if (this.isProjectMeeting == true) {
              this.meetingInfo.projectId = this.porjectListOptions[0].id;
            }
            if (this.isInvestMeeting == true) {
              this.meetingInfo.institutionId = this.porjectListOptions[0].id;
              if (this.type == "add") {
                console.log("投资机构id---" + this.meetingInfo.institutionId);
                this.institutionIdChange(this.meetingInfo.institutionId);
              }
            }
          } else {
            if (this.isProjectMeeting == true && !this.options) {
              this.meetingInfo.projectId = null;
            }
            if (this.isInvestMeeting == true && !this.options) {
              this.meetingInfo.institutionId = null;
            }
          }
          this.$set(this.meetingInfo, "subject", schedule.title);
          this.$set(this.meetingInfo, "beginTime", schedule.beginTime);
          this.meetingInfo.internalUserList = [];
          schedule.userList.map((item, index) => {
            this.$set(this.meetingInfo.internalUserList, index, item.userId);
          });
          // if (this.options) {
          //   setTimeout(() => {
          //     this.options = null;
          //   }, 500);
          // }
          this.$forceUpdate();
        }
      }
    },

    meetingTypeChanged(type) {
      if (type === "project") {
        this.isInvestMeeting = false;
        this.isOtherMeeting = false;
      } else if (type === "invest") {
        console.log("32323232");
        this.isProjectMeeting = false;
        this.isOtherMeeting = false;
      } else if (type === "other") {
        this.isInvestMeeting = false;
        this.isProjectMeeting = false;
      }

      if (this.isProjectMeeting == true) {
        this.meetingInfo.institutionId = null;
        this.meetingInfo.businessId = null;
        this.meetingInfo.meetingType = "project";
        this.meetingInfo.businessType = 3;
        this.meetingInfo.investorTaskId = null;
        this.meetingInfo.projectWorkflowDetailId = null;
      } else if (this.isInvestMeeting == true) {
        console.log("32323232");
        this.meetingInfo.projectId = null;
        this.meetingInfo.projectWorkflowDetailId = null;
        this.meetingInfo.businessId = null;
        this.meetingInfo.meetingType = "invest";
        this.meetingInfo.businessType = 2;
        this.meetingInfo.investorTaskId = null;
      } else if (this.isOtherMeeting == true) {
        this.meetingInfo.institutionId = null;
        this.meetingInfo.projectId = null;
        this.meetingInfo.projectWorkflowDetailId = null;
        this.meetingInfo.businessId = null;
        this.meetingInfo.investorTaskId = null;
        this.meetingInfo.meetingType = "other";
        this.meetingInfo.businessType = 3;
      } else {
        this.meetingInfo.meetingType = "schedule";
        this.meetingInfo.projectId = null;
        this.meetingInfo.institutionId = null;
        this.meetingInfo.businessId = null;
        this.meetingInfo.businessType = 0;
        this.meetingInfo.investorTaskId = null;
        this.meetingInfo.projectWorkflowDetailId = null;
      }

      this.$nextTick(() => {
        this.$refs["meetingInfo"].clearValidate();
      });
    },
    getInstitutionTaskSelectList(cb) {
      if (cb) {
        // this.institutionTaskSelectList = [];
        if (!this.meetingInfo.projectWorkflowDetailId) {
          this.$message({ type: "warning", message: "请先选择业务流程节点!" });
          return false;
        }
        let info = {
          relationType: 1,
          relationBizId: this.meetingInfo.projectWorkflowDetailId,
        };

        getTaskSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;
            this.institutionTaskSelectList = list;
          }
        });
      }
    },
    institutionTaskChange(event) {
      console.log(event);
      // this.meetingInfo.investorTaskId = event;
      this.$set(this.meetingInfo, "investorTaskId", event);
      this.$forceUpdate();
    },
    getOtherTaskSelectList(cb) {
      this.otherTaskSelectList = [];

      if (cb) {
        let info = {
          mode: 2,
          displayStatusList: [5],
          pageNum: 1,
          pageSize: 100,
        };

        getTaskQueryList1(info).then((response) => {
          if (response.success) {
            let list = response.data;
            this.otherTaskSelectList = list;
          }
        });
      }
    },

    editDisabled(group) {
      if (this.type === "add") {
        switch (group) {
          case "group2":
            if (this.options.extId && this.options.extType) {
              return true;
            } else {
              return false;
            }
        }
        return false;
      } else {
        switch (group) {
          case "group1":
            if (this.oldMeetingInfo.relatedScheduleTitle) {
              return true;
            } else {
              return false;
            }
            break;
          case "group2":
            if (
              this.oldMeetingInfo.relatedScheduleId &&
              this.oldMeetingInfo.meetingType != "schedule"
            ) {
              return true;
            } else {
              return false;
            }
            break;
          case "group3":
            let boolean;
            if (this.options.extId && this.options.extType) {
              boolean = false;
            } else {
              if (
                this.oldMeetingInfo.relatedScheduleId &&
                this.oldMeetingInfo.meetingType != "schedule"
              ) {
                boolean = true;
              } else {
                boolean = false;
              }
            }
            return boolean;

            break;
        }
        return true;
      }
    },
  },

  computed: {},
};
</script>

<style scoped lang="scss">
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}

.el-icon-iconName {
  margin: 0 5px;
}

.addDailogEdit {
  // padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;

  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }

  .el-form-item {
    text-align: left;
    width: 32%;
    margin-bottom: 18px;
    margin-right: 10px;

    // display: flex;
    //width: 100% !important;
    // float: left;
    //min-height: 1rem;

    .el-select {
      width: 100% !important;
    }

    .el-input-number {
      width: 100% !important;
      text-align: left !important;

      .el-input__inner {
        text-align: left !important;
      }
    }

    .el-date-picker {
      width: 100% !important;
    }
  }
}

#wangeditor {
  // width: 1000px;
  // height: 15rem;
}

.w-e-menu {
  z-index: 2 !important;
}

.w-e-text-container {
  z-index: 1 !important;
}
</style>
