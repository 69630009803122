<template>
  <div>
    <el-main>
      <div class="top flexAcJcfe">
        <!-- <el-button type="primary" @click="queryInvestorList(1)">查询</el-button> -->
        <el-button
          type="primary"
          @click="newMeeting"
          v-permission="['admin:meeting:add']"
          >新增会议纪要
        </el-button>
      </div>
      <el-form
        :model="params"
        ref="addProject"
        class="addDailog"
        onsubmit="return false;"
        label-width="140px"
        label-position="right"
      >
        <el-form-item label="项目/投资机构" prop="projectName">
          <el-input
            type="text"
            v-model="params.projectName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="会议名称" prop="subject">
          <el-input type="text" v-model="params.subject" clearable></el-input>
        </el-form-item>
        <el-form-item label="业务流程" prop="workflowId">
          <el-select
            v-model="params.workflowId"
            @visible-change="technologicalList($event)"
            placeholder="请选择"
            filterable
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in technological"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建人" prop="createUser">
          <el-select
            v-model="params.createUser"
            filterable
            :reserve-keyword="false"
            placeholder="请输入关键词从中搜索"
            @visible-change="projectTeam($event)"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in internalUseroptionsList"
              :key="item.uid"
              :label="item.realName"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="searchBeginTime">
          <el-date-picker
            v-model="params.searchBeginTime"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
            style="width: 100% !important"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="searchEndTime">
          <el-date-picker
            v-model="params.searchEndTime"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
            style="width: 100% !important"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="会议类型" prop="meetingType">
          <el-select
            v-model="params.meetingType"
            class="childWidth"
            filterable
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in meetingTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div style="margin-left: 80px; float: right">
          <el-button @click="resetConditions('addProject')">重置</el-button>
          <el-button
            type="primary"
            @click="queryMeetingList(1, pageSize, params)"
            >查询
          </el-button>
        </div>
      </el-form>
      <!-- 表格 -->
      <el-table
        border
        v-loading="loadingInvestorTable"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
      >
        <el-table-column
          label="会议主题"
          width="140"
          prop="subject"
        ></el-table-column>
        <el-table-column
          label="日程"
          prop="relatedScheduleTitle"
        ></el-table-column>
        <el-table-column
          label="项目/投资机构"
          width="110"
          prop="projectInstitutionName"
        ></el-table-column>
        <el-table-column label="流程/投资人" width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.meetingType === 'project'">
              <span>{{ scope.row.projectWorkflowName }}</span>
              <span
                v-show="scope.row.projectWorkflowName"
                class="workflowVersion"
              >
                <br />版本{{ scope.row.workflowVersion }}</span
              >
            </span>
            <span v-if="scope.row.meetingType === 'invest'">
              {{ scope.row.investorName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="节点" prop="nodeName"></el-table-column>
        <el-table-column
          label="关联任务"
          prop="taskOtherTaskName"
        ></el-table-column>
        <el-table-column label="会议日期" prop="createDate" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.beginTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="与会外部人员"
          prop="externalUsers"
          width="110"
        ></el-table-column>
        <el-table-column label="内部人员" prop="place">
          <template slot-scope="scope">
            <span
              class="span-tag-list"
              v-for="(item, index) in scope.row.internalUserList"
              :key="index"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.realName"
                placement="top"
              >
                <span
                  >{{ index + 1 }}.{{ item.realName }} <span class="d">,</span>
                </span>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column v-if="false" label="待办事项" prop="place">
          <template slot-scope="scope">
            <span
              class="span-tag-list"
              v-for="(item, index) in scope.row.matterList"
              :key="item.id"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.content"
                placement="top"
              >
                <span
                  >{{ index + 1 }}.{{ item.content }} <span class="d">,</span>
                </span>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="creatorName"></el-table-column>
        <el-table-column label="创建时间" prop="createDate" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.createDate | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="会议类型" width="100" prop="createDate">
          <template slot-scope="scope">
            <span>{{ scope.row.meetingType | transMeetingType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="220">
          <template slot-scope="scope">
            <template v-if="scope.row.hasAuth === 1">
              <el-button
                type="primary"
                size="mini"
                @click="openDetailsDialog(scope.row.id)"
                v-permission="['admin:meeting:find_detail']"
              >详情
              </el-button>
              <el-button
                v-show="scope.row.isAlive != 0"
                type="primary"
                size="mini"
                @click="editMeeting(scope.row)"
                v-permission="['admin:meeting:edit']"
              >编辑
              </el-button>
              <el-button
                v-show="scope.row.isAlive != 0"
                type="primary"
                size="mini"
                @click="deleteMeeting(scope.row.id)"
                v-permission="['admin:meeting:delete']"
              >删除
              </el-button>
            </template>
            <template v-else>
              <data-auth-apply :info="{event: 'primary', target: 't_meeting', targetId: scope.row.id, ability: 10}" />
            </template>
          </template>
        </el-table-column>
      </el-table>

      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="this.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>

      <!-- 详情 -->
      <meetingDetails
        v-if="detailsDialog"
        :detailsDialog="detailsDialog"
        v-on:listenDetailsClose="listenDetailsClose"
        :id="meetingInfoId"
      ></meetingDetails>

      <el-dialog
        :title="editTitle"
        :visible.sync="showNewMeeting"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :lock-scroll="true"
        width="1100px"
      >
        <edit
          v-if="showNewMeeting"
          :threadId="meetingThreadId"
          :options="meetingOptions"
          @close="closeNewMeetingDialog"
          :jumpBack="jumpBack"
        ></edit>
      </el-dialog>
    </el-main>
  </div>
</template>

<script>
//now
import { formatDate } from "@/common/date";
import { queryMeetingList, deleteMeeting } from "@/api/minutesOfMeeting";
import meetingDetails from "../components/minutesOfMeeting/meetingDetails";
import { projectTeam, selectTypeProject } from "@/api/projectManage";
import edit from "../components/myMission/edit";
import eventBus from "@/common/eventBus";
import DataAuthApply from "@/components/dataAuth/dataAuthApply";

export default {
  name: "minutesOfMeeting",
  data() {
    var checkName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入姓名"));
      }
      callback();
    };

    var checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      }
      callback();
    };

    var checkWechatId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入微信号"));
      }
      callback();
    };
    return {
      //now
      pageNum: 1,
      pageSize: 10,
      total: null,
      pageCount: null,
      typeDialog: null,
      editDialog: false, //打开编辑
      detailsDialog: false, //打开详情
      tableData: [],
      loadingInvestorTable: false,
      meetingInfoId: null,
      businessType: 2, //会议类型：0 默认，1 项目流程节点会议，2 投资人会议
      params: {
        projectName: null,
        subject: null,
        workflowId: null,
        createUser: null,
        searchBeginTime: null,
        searchEndTime: null,
      },
      businessTypeList: [
        {
          value: 3,
          label: "项目会议(任务)",
        },

        {
          value: 2,
          label: "投资人会议",
        },
        {
          value: 1,
          label: "项目会议(流程节点)",
        },
      ],
      meetingTypeList: [
        {
          value: "project",
          label: "项目会议",
        },
        {
          value: "invest",
          label: "投资人会议",
        },
        {
          value: "other",
          label: "其它会议",
        },
        {
          value: "schedule",
          label: "日程会议",
        },
      ],
      internalUseroptionsList: null,
      technological: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      noProjectTask: null,

      showNewMeeting: false, //是否显示新增会议纪要弹窗
      meetingThreadId: 1,
      meetingOptions: { type: "add" }, // 会议纪要的传入参数
      // jumpBack: null, //新增、编辑会议纪要之后是否跳转回去
      editTitle:null,
    };
  },
  components: {
    DataAuthApply,
    meetingDetails,
    edit,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    businessType(value) {
      switch (value) {
        case 1:
          return "业务流程节点会议";
          break;
        case 2:
          return "投资人会议";
          break;
        case 3:
          return "项目会议(任务)";
          break;
      }
    },
  },
  activated() {
    this.queryMeetingList(this.pageNum, this.pageSize);
  },
  deactivated() {
    this.noProjectTask = null;
  },
  props: {
    tabIndex: String,
    threadId: Number,
    options: Object,
    jumpBack: String,
  },
  created() {},
  mounted() {
    // this.queryMeetingList(1, this.pageSize);
  },
  methods: {
    resetConditions() {
      this.$refs["addProject"].resetFields(); //重置form
      this.queryMeetingList(1, this.pageSize);
    },
    technologicalList(cb) {
      if (cb) {
        //流程列表
        selectTypeProject({}).then((res) => {
          if (res.success) {
            this.technological = res.data;
          }
        });
      }
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    // 删除
    deleteMeeting(id) {
      this.$confirm("确定要删除此记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteMeeting(id).then((response) => {
            if (response.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.queryMeetingList(this.pageNum, this.pageSize);
              eventBus.$emit("deleteMeetingDetail");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //新增编辑弹窗显示
    openDialog(type, val, name) {
      // console.log(id);
      if (type == "edit") {
        this.typeDialog = "edit";
        this.meetingInfoId = val.id;
        this.businessType = val.businessType;
      } else if (type == "add") {
        this.typeDialog = "add";
      }
      this.editDialog = true;
    },
    //新增编辑弹窗显示
    openDetailsDialog(id) {
      console.log(id);
      this.meetingInfoId = id;
      this.detailsDialog = true;
    },
    //监听详情弹窗关闭
    listenDetailsClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.detailsDialog = !isClose;
      if (type == "sure") {
        this.queryMeetingList(this.pageNum, this.pageSize);
      }
    },
    //before

    queryMeetingList(num, pageSize) {
      let query = {};
      query = { ...this.params };
      query.pageNum = num;
      query.pageSize = pageSize;
      this.loadingInvestorTable = true;
      console.log(query);
      queryMeetingList(query).then((response) => {
        this.tableData = response.data;
        if (response.total != null) {
          this.pageNum = response.pageNum;
          this.pageSize = response.pageSize;
          this.total = response.total;
          this.pageCount = response.pageCount;
        } else {
          this.total = 0;
        }
        this.loadingInvestorTable = false;
      });
    },
    handleSizeChange(val) {
      this.queryMeetingList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.queryMeetingList(val, this.pageSize);
    },

    displayDetail(id) {
      this.$store.commit("setRouterType", "openWindow");
      const { href } = this.$router.resolve({
        name: `investorDetail`,
        query: {
          id: id,
        },
      });
      console.log(href);
      window.open(href);
    },

    newMeeting(info) {
      this.editTitle = "新增会议纪要"

      // this.$emit('new-meeting', {meetingType: 'invest'});
      if (!info) {
        info = {};
      }
      const meetingType = info.meetingType;

      this.meetingOptions = {
        type: "add",
        meetingType: meetingType ,
      };
      this.meetingThreadId++;
      this.showNewMeeting = true;
    },

    editMeeting(row) {
      // this.$emit('edit-meeting', row);
      this.editTitle = "编辑会议纪要"
      this.meetingOptions = {
        type: "edit",
        meetingId: row.id,
      };
      this.meetingThreadId++;
      this.showNewMeeting = true;
    },

    closeNewMeetingDialog(params) {
      console.log(params)
      if (params && params.jumpBack == "minutesOfMeeting") {
        this.$emit("listenJumpBack", {
          jumpBack: "minutesOfMeeting",
        });
      }
      this.showNewMeeting = false;
      this.queryMeetingList(this.pageNum, this.pageSize);

      // eventBus.$emit("editMeetingDetail");
    },
  },
  watch: {
    tabIndex(newVal, old) {
      if (newVal == "5") {
        if (!this.total) {
          this.queryMeetingList(this.pageNum, this.pageSize);
        }
      }
    },

    threadId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.options.type === "new_meeting") {
            this.meetingOptions = this.options.meetingOptions;
            this.meetingThreadId++;
            this.showNewMeeting = true;
          }
        }
      },
      immediate: true,
    },

    showNewMeeting: {
      handler(newVal, old) {
        if (newVal) {
          this.$store.dispatch("lockScroll", { value: true });
        } else {
          this.$store.dispatch("lockScroll", { value: false });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-bottom: 10px;
}

.el-table--border th.gutter:last-of-type {
  display: block !important;
  width: 17px !important;
}

.col-content-border {
  border: 1px #999999 solid;
  border-radius: 5px;
}

.restButton {
  float: right;
  margin-right: 10%;
  margin-bottom: 15px;
}

.el-row {
  flex-wrap: wrap;
}

.el-col {
  flex-shrink: 0;
}

.ulBox {
  margin-bottom: 30px;
  overflow: hidden;
  padding: 0;
}

.ulBox li {
  min-width: 200px;
  float: left;
  list-style: none;
}

.span-tag-list {
  .item:last-child .d {
    display: none;
  }
}

.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;

  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }

  .el-form-item {
    text-align: left;

    // display: flex;
    width: 48% !important;
    // float: left;
    min-height: 1rem;

    .el-select {
      width: 100% !important;
    }

    .el-input-number {
      width: 100% !important;
      text-align: left !important;

      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}

.workflowVersion {
  color: #ccc;
  font-size: 12px;
}
</style>
