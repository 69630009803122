/*
 * @Description:
 * @Author: transparent
 * @Date: 2022-01-14 16:12:42
 * @LastEditTime: 2022-01-17 14:13:07
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/common/quill-config.js
 */
import axios from "axios";
import Quill from "quill"; //引入编辑器
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
import { authorizationAdd, upLoad } from "@/api/processProgress";
/*富文本编辑图片上传配置*/
const uploadConfig = {
  action: "", // 必填参数 图片上传地址
  methods: "POST", // 必填参数 图片上传方式
  token: "", // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
  name: "img", // 必填参数 文件的参数名
  size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
  accept: "image/png, image/gif, image/jpeg, image/bmp, image/x-icon" // 可选 可上传的图片格式
};

// toolbar工具栏的工具选项（默认展示全部）
const toolOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
  ["link","image","video"]
];
const handlers = {
  image: function image() {
    var that = this;

    var fileInput = this.container.querySelector("input.ql-image[type=file]");
    if (fileInput === null) {
      fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      // 设置图片参数名
      if (uploadConfig.name) {
        fileInput.setAttribute("name", uploadConfig.name);
      }
      // 可设置上传图片的格式
      fileInput.setAttribute("accept", uploadConfig.accept);
      fileInput.classList.add("ql-image");
      // 监听选择文件
      fileInput.addEventListener("change", function() {
        let pro = new Promise((resolve, rej) => {
          // 判断签名有没有过期
          var res = JSON.parse(localStorage.getItem("sign"));
          var timestamp = Date.parse(new Date()) / 1000;
          if (res && res.expire - 3 > timestamp) {
            resolve(res);
          } else {
            let data = {
              directory: "project"
            };
            authorizationAdd(data).then(res => {
              if (res.success) {
                localStorage.setItem("sign", JSON.stringify(res.data));
                resolve(res.data);
              }
            });
          }
        });
        pro.then(success => {
          var data = success;
          var formData = new FormData();
          let uuid = uuidv4();

          let file = fileInput.files[0];
          //key就代表文件层级和阿里云上的文件名
          let imgType = file.type.split("/")[1];
          let filename = `${uuid}.${imgType}`;
          console.log(file.name); //文件原本的名字
          // 文件名字，可设置路径
          formData.append("key", `${data.directory}${filename}`);
          // policy规定了请求的表单域的合法性
          formData.append("policy", data.policy);
          // Bucket 拥有者的Access Key Id
          formData.append("OSSAccessKeyId", data.accessId);
          // 让服务端返回200,不然，默认会返回204
          formData.append("success_action_status", "200");
          // 根据Access Key Secret和policy计算的签名信息，OSS验证该签名信息从而验证该Post请求的合法性
          // formData.append('callback', res.data.callback)
          formData.append("signature", data.signature);
          formData.append("name", file.name);

          //Content-Disposition生效必须放在formData.append("file", val.file, filename);前
          formData.append(
            "Content-Disposition",
            "inline; filename=" + file.name
          );
          // 需要上传的文件filer
          formData.append("file", file, filename);
          upLoad(data.host, formData).then(res => {
            if (res.status == 200) {
              let url = data.host + "/" + data.directory + filename;
              let length = that.quill.getSelection(true).index;
              //图片上传成功后，img的src需要在这里添加
              that.quill.insertEmbed(length, "image", url);
              that.quill.setSelection(length + 1);
              console.log(url);

              fileInput.value = "";
              this.upLoadButton = false;
            }
          });
        });
      });
      this.container.appendChild(fileInput);
    }
    fileInput.click();
  }
};
let uuidv4 = function() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
export default {
  placeholder: "请输入会议内容",
  theme: "snow", // 主题
  modules: {
    toolbar: {
      container: toolOptions, // 工具栏选项
      handlers: handlers, // 事件重写
    },
    imageDrop: true,
    imageResize: {
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white"
      }
    }
  }
};
